import { useState } from "react";
import WooCommerceProducts from "./components/WooCommerceProducts";
import { PiShoppingBagFill } from "react-icons/pi";
import ShopfiyProducts from "./components/ShopfiyProducts";
import ImmersifyProducts from "./components/ImmersifyProducts";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const Products = () => {
  const [activeTab, setActiveTab] = useState(0);

  const userId = useSelector(state => state?.auth?.auth?.user?._id)
  console.log("user id ", userId);
  

  const tabs = ["Woocomerce", "Shopify", "Immarsify"];

  const copyToClipboard = () => {
    const text = `https://tryon.immarsify.com/tryon/store/${userId}`
    navigator.clipboard.writeText(text)
      .then(() => {
        // Optionally, you can show a message or change the button's state
        toast.success("Kiosk link copied to clipboard!")
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div className="w-full p-2">
      <div className="container mx-auto rounded-lg bg-white shadow-md">

        {/* Desktop Tabs */}
        <div className="hidden sm:flex border-b justify-between w-full p-1">
          <div className="flex">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`px-4 py-2 focus:outline-none transition-colors duration-300 ${activeTab === index
                    ? "border-b-2 border-brand-main text-brand-main font-semibold"
                    : "text-gray-500 hover:text-brand-main"
                  }`}
                onClick={() => setActiveTab(index)}
              >
                {tab}
              </button>
            ))}
          </div>

          <button onClick={copyToClipboard} type="button" className="px-3 py-1 bg-blueSecondary text-white font-medium rounded-xl">
            Share Kiosk
          </button>

        </div>

        {/* Mobile Tabs */}
        <div className="flex justify-center p-4 sm:hidden">
          <FormControl fullWidth variant="outlined" size="small">
            <Select
              value={activeTab}
              onChange={(e) => setActiveTab(Number(e.target.value))}
              displayEmpty
              className="rounded-md bg-white text-brand-main"
            >
              {tabs.map((tab, index) => (
                <MenuItem key={index} value={index}>
                  {tab}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Tab Content */}
        <div className="p-4">
          {activeTab === 0 && <WooCommerceProducts />}
          {activeTab === 1 && <ShopfiyProducts />}
          {activeTab === 2 && <ImmersifyProducts />}
        </div>
      </div>
    </div>
    // <div className="w-full ">
    //   <div className="container w-full  rounded-lg bg-white">
    //     <div className="hidden border-b sm:flex  ">
    //       {tabs.map((tab, index) => (
    //         <button
    //           key={index}
    //           className={`px-4 py-2 focus:outline-none ${activeTab === index
    //             ? "border-b-2 border-brand-main text-brand-main"
    //             : "text-gray-500"
    //             }`}
    //           onClick={() => setActiveTab(index)}
    //         >
    //           {tab}
    //         </button>
    //       ))}
    //     </div>

    //     <div className="tabs_mobile flex justify-center  p-2 sm:hidden">
    //       <FormControl fullWidth variant="outlined" size="small">
    //         {/* <InputLabel id="tab-select-label">Select Tab</InputLabel> */}
    //         <Select
    //           // labelId="tab-select-label"
    //           value={activeTab}
    //           id="tab-select"
    //           className="rounded-md w-full text-center bg-[#fff] text-brand-main"
    //           onChange={(e) => {
    //             console.log(e.target.value);
    //             setActiveTab(Number(e.target.value));
    //           }}
    //           // label="Select Tab"
    //         >
    //           {tabs.map((tab, index) => (
    //             <MenuItem key={index} value={index}>
    //               {tab}
    //             </MenuItem>
    //           ))}
    //         </Select>
    //       </FormControl>
    //       {/* <select
    //         name=""
    //         id=""
    //         className="rounded-md w-full p-3 text-center bg-[#fff] text-brand-main  p-1 "
    //         style={{ border: '1px solid #3d2fa9' }}
    //         onChange={(e) => {
    //           console.log(e.target.value);
    //           setActiveTab(Number(e.target.value));
    //         }}
    //       >
    //         {tabs.map((tab, index) => {
    //           return <option value={index}>{tab}</option>;
    //         })}
    //       </select> */}
    //     </div>
    //     <div className="p-4">
    //       {activeTab === 0 && <WooCommerceProducts />}
    //       {activeTab === 1 && <ShopfiyProducts />}
    //       {activeTab === 2 && <ImmersifyProducts />}
    //     </div>
    //   </div>
    // </div>
  );
};

export default Products;
