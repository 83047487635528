import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useFormik } from "formik";

import * as Yup from "yup";

import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import "./FileUpload.css";
import Slide from "@mui/material/Slide";
import { IoMdClose } from "react-icons/io";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Modal, Select, Switch, TextField, getLinearProgressUtilityClass } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { IoCloseCircleSharp } from "react-icons/io5";
import { SliderPicker } from "react-color";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tryOnTypes = [
  {
    id: 1,
    value: 'sunglasses',
    text: "Sun Glasses"
  },
  {
    id: 2,
    value: 'earring',
    text: 'Ear Rings'
  },
  {
    id: 3,
    value: 'necklace',
    text: 'Necklace'
  }
]

export default function AddProductModal({
  isOpen,
  setIsOpen,
  getImmersifyProducts,
  category,
}) {
  const [productAvailableCheck, setProductAvailableCheck] = useState(false);
  const [productDisplayCheck, setProductDisplayCheck] = useState(false);
  const [variantsCheck, setVarientCheck] = useState(false);
  const [recommandCheck, setRecommandCheck] = useState(false);
  const [uploadedMainImage, setUploadedMainImage] = useState(null);
  const [uploadedTryOnImage, setUploadedTryOnImage] = useState(null);
  const [selectedMainImage, setSelectedMainImage] = useState(null);
  const [selectedTryOnImage, setSelectedTryOnImage] = useState(null);
  const [selectedGallaryImages, setSelectedGallaryImages] = useState([]);
  const [uploadedGallaryImagesLink, setUploadedGallaryImagesLink] = useState([]);
  const [selectedTryOnType, setSelectedTryOnType] = useState({ id: 1, value: 'sunglasses', text: "Sun Glasses" })
  const [selectedMainImageBinary, setSelectedMainImageBinary] = useState(null);
  const [selectedTryOnImageBinary, setSelectedTryOnImageBinary] = useState(null);
  const [selectedGallaryImageBinary, setSelectedGallaryImageBinary] = useState(
    []
  );
  const [tryOnImagesBinary, setTryOnImagesBinary] = useState([])
  const [openTryOnModal, setOpenTryOnModal] = useState(false);
  const [tryOnImages, setTryOnImages] = useState([]);
  const [color, setColor] = useState('');
  const [tryOnColors, setTryOnColors] = useState([])


  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .required("Name is required"),
    description: Yup.string()
      .min(10, "Description must be at least 10 characters")
      .required("Description is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .positive("Price must be positive")
      .required("Price is required"),
    taxPercentage: Yup.number()
      .typeError("Tax percentage must be a number")
      .min(0, "Tax percentage cannot be less than 0")
      .max(100, "Tax percentage cannot be more than 100")
      .required("Tax percentage is required"),
    comparisionUrl: Yup.string(),
    shapes: Yup.array(),
    discountPercentage: Yup.number()
      .typeError("Discount percentage must be a number")
      .min(0, "Tax percentage cannot be less than 0")
      .max(100, "Tax percentage cannot be more than 100")
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: 0,
      taxPercentage: 0,
      comparisionUrl: '',
      shapes: [],
      discountPercentage: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await createProductHandler(values, resetForm);
      console.log(values, "form is sumitted");
    },
  });

  const userToken = useSelector((state) => state.auth.auth.token);


  const handleClose = () => {
    setIsOpen(false);
  };
  
  const filterGallaryImages = (mainIndex) => {
    const imgs = selectedGallaryImages.filter(
      (img, index) => mainIndex !== index
    );
    setSelectedGallaryImages(imgs);
  };


  const handleMainImageChange = async (event) => {
    try {
      const files = Array.from(event.target.files);
      console.log(files);
      const validFiles = files.filter((file) =>
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      console.log(files, validFiles);
      if (validFiles.length < 1) {
        toast.error("Please upload valid image");
        return;
      }
      const imageUrl = URL.createObjectURL(validFiles[0]);
      setSelectedMainImage(imageUrl);
      setSelectedMainImageBinary(files[0]);
    } catch (error) {
      console.log("error main image upload ", error);

    }
  };



  const handleGallaryImageChange = async (event) => {
    try {
      const files = Array.from(event.target.files);

      // Filter valid image files based on file type
      const validFiles = files.filter((file) =>
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );

      // Check if any invalid files were selected
      const invalidFiles = files.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );

      // Display toast for invalid files
      if (invalidFiles.length > 0) {
        toast.error("Please select only JPEG, PNG, or JPG images");
        return;
      }

      // Map valid files to image URLs for preview
      const imageUrls = validFiles.map((file) => URL.createObjectURL(file));

      // Update selected gallery images in state
      setSelectedGallaryImages(imageUrls);
      console.log(validFiles, "uploading image");

      // Update selected gallery image binaries in state
      setSelectedGallaryImageBinary(validFiles);

      // Uncommented code for handling image upload and updating image links
      // validFiles.forEach(async (file) => {
      //   const link = await updloadImageHandler(file);
      //   console.log(link, "this is link");
      //   setSelectedGallaryImagesLink((prev) => {
      //     return [...prev, link];
      //   });
      // });

      // Uncommented code for updating gallery images in state with URLs
      // setSelectedGallaryImages((prevImages) => [...prevImages, ...imageUrls]);
    } catch (error) {
      console.error("Error handling gallery image change:", error);
    }
  };
  const featureHandler = (type) => {
    if (type === "productAvailable") {
      setProductAvailableCheck((prev) => !prev);
    }
    if (type === "productDisplay") {
      setProductDisplayCheck((prev) => !prev);
    }
    if (type === "recommand") {
      setRecommandCheck((prev) => !prev);
    }
    if (type === "variants") {
      setVarientCheck((prev) => !prev);
    }
  };

  const createProductHandler = async (values, resetForm) => {
    try {
      if (!selectedMainImageBinary) {
        toast.error("Please upload main image");
        return;
      }

      if (tryOnImagesBinary?.length < 1) {
        toast.error("Please upload atleast one Try On image!");
        return;
      }

      // const tryOnBinary = tryOnImages?.forEach((item) => {
      //   const binary = Uitem?.image)
      //   return { color: item?.color, image: binary }
      // })

      const { name, description, price, taxPercentage, comparisionUrl, shapes, discountPercentage } = values;

      // Create a FormData object
      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('price', price);
      formData.append('taxPercentage', taxPercentage);
      formData.append('discount', discountPercentage);
      // formData.append('mainImage', uploadedMainImage); // assuming this is a file
      formData.append('mainImage', selectedMainImageBinary);
      formData.append('isProductAvailable', productAvailableCheck);
      formData.append('isDisplayProduct', productDisplayCheck);

      formData.append('category', category?._id); // assuming this is a string
      formData.append('comparisionUrl', comparisionUrl);
      // If you have multiple gallery images, append each one
      selectedGallaryImageBinary.forEach(image => {
        formData.append('galleryImages', image); // append each gallery image
      });
      formData.append('faceCategories', JSON.stringify(shapes));
      const images = Array.isArray(tryOnImagesBinary) ? tryOnImagesBinary : [tryOnImagesBinary];

      // Append each image to formData
      images.forEach(item => {
        formData.append('glassImages', item);
      });

      // tryOnImagesBinary?.forEach(item => {
      //   formData.append('glassImages', item)
      // })

      const colors = Array.isArray(tryOnColors) ? tryOnColors : [tryOnColors];

      // Append each color in the array
      colors.forEach(item => {
        formData.append('glassColors', item);
      });

      // tryOnColors?.forEach(item => {
      //   formData.append('glassColors', item)
      // })

      formData?.append('glassImageType', selectedTryOnType?.value)

      // ImageType', selectedTryOnType?.value);

      // Handling try-on images
      // if (selectedTryOnImageBinary) {
      //   formData.append('glassImage', selectedTryOnImageBinary);
      //   formData.append('glassImageType', selectedTryOnType?.value);
      // }

      const response = await instance.post(
        "api/products/create_product",
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Important for file uploads
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      console.log(response, "this is response");
      if (response.status === 201) {
        resetForm();
        toast.success(response?.data?.message);
        getImmersifyProducts();

        // Clear state after successful submission
        setSelectedMainImage(null);
        setUploadedMainImage(null);
        setSelectedMainImageBinary(null);
        setSelectedTryOnImage(null);
        setUploadedTryOnImage(null);
        setSelectedTryOnImageBinary(null);
        setSelectedGallaryImages([]);
        setUploadedGallaryImagesLink([]);
        setSelectedGallaryImageBinary([]);

        setTimeout(() => {
          setIsOpen(false);
        }, 1000);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log("Dashboard Error: ", error);
    }
  };

  const handleTryOnImageChange = async (event) => {
    try {
      const files = Array.from(event.target.files);
      console.log(files);
      const validFiles = files.filter((file) =>
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      console.log(validFiles);
      if (validFiles.length < 1) {
        toast.error("Please upload valid image");
        return;
      }
      const imageUrl = URL.createObjectURL(validFiles[0]);
      setSelectedTryOnImage(imageUrl);

      setSelectedTryOnImageBinary(validFiles[0]);
    } catch (error) {
      console.log("error try on image upload ", error);

    }
  };

  const handleDone = () => {
    if (!color || !selectedTryOnImage) {
      toast.error("Please enter color and select an image");
      return;
    }


    setTryOnImages((prevImages) => [...prevImages, selectedTryOnImage]);
    setTryOnColors(prev => [...prev, color])
    setTryOnImagesBinary((prev) => [...prev, selectedTryOnImageBinary])
    setOpenTryOnModal(false);
    setColor('');
    setSelectedTryOnImage(null);
    setSelectedTryOnImageBinary(null);
  };

  const handleTryOnTypeChange = (e) => {
    const category = tryOnTypes.find(
      (category) => category?.value === e.target.value
    );
    console.log(category, "category found");
    if (category) {
      setSelectedTryOnType(category);
    }
  };

  console.log(uploadedGallaryImagesLink, "this is uploaded");
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", background: "white", color: "black" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <IoMdClose />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Product
            </Typography>
            <buttton
              onClick={formik.handleSubmit}
              className="   cursor-pointer rounded-lg border border-none border-gray-300  bg-brand-main/90 hover:bg-brand-main px-4 py-2 text-white outline-none transition duration-300 ease-in-out"
            >
              Save
            </buttton>
          </Toolbar>
        </AppBar>
        <div className="w-full flex flex-col md:flex-row items-start justify-between gap-4 p-6 sm:px-12">
          <div className="w-full md:w-6/12 flex flex-col gap-4">
            <div className="w-full bg-white drop-shadow-md rounded-lg">
              <div className="header flex justify-between rounded-t-lg bg-black/10 p-4">
                <h3 className="text-xl">Product to Management</h3>
              </div>
              <div className=" p-6">
                <div className="relative mb-4 flex flex-col">
                  <label
                    for="name"
                    className="leading-7 text-black font-medium"
                  >
                    Name
                  </label>
                  <TextField
                    id="name outlined-basic"
                    name="name"
                    placeholder="John Wick"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    onBlur={formik.handleBlur}
                    // variant="none"
                    className=" bg-black/5"
                  />

                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-sm text-red-500">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="relative mb-4 flex flex-col">
                  <label
                    for="description"
                    className="leading-7 text-black font-medium"
                  >
                    Description
                  </label>
                  <TextField
                    id="description outlined-basic"
                    name="description"
                    multiline
                    minRows={2}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    className="bg-black/5"
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="text-sm text-red-500">
                      {formik.errors.description}
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <div className="relative  w-full md:w-1/2 md:mb-4 flex flex-col">
                    <label
                      for="price"
                      className="leading-7 text-black font-medium"
                    >
                      Price
                    </label>
                    <TextField
                      id="price outlined-basic"
                      name="price"
                      onChange={formik.handleChange}
                      value={formik.values.price}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size="small"
                      type="number"
                      className="bg-black/5"
                    />

                    {formik.touched.price && formik.errors.price ? (
                      <div className="text-sm text-red-500">
                        {formik.errors.price}
                      </div>
                    ) : null}
                  </div>
                  <div className="relative mb-4 w-full md:w-1/2 flex flex-col ">
                    <label
                      for="taxPercentage"
                      className="leading-7 text-black font-medium"
                    >
                      Tax Perccentage
                    </label>
                    <TextField
                      id="taxPercentage outlined-basic"
                      name="taxPercentage"
                      onChange={formik.handleChange}
                      value={formik.values.taxPercentage}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      size="small"
                      type="number"
                      className="bg-black/5"
                    />

                    {formik.touched.taxPercentage &&
                      formik.errors.taxPercentage ? (
                      <div className="text-sm text-red-500">
                        {formik.errors.taxPercentage}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="relative mb-4 flex flex-col">
                  <label
                    for="discountPercentage"
                    className="leading-7 text-black font-medium"
                  >
                    Discount Percentage
                  </label>
                  <TextField
                    id="discountPercentage outlined-basic"
                    name="discountPercentage"
                    placeholder="min 0, max 100"
                    onChange={formik.handleChange}
                    value={formik.values.discountPercentage}
                    onBlur={formik.handleBlur}
                    size="small"
                    type="number"
                    // variant="none"
                    className=" bg-black/5"
                  />
                </div>
                <div className="relative mb-4 flex flex-col">
                  <label
                    for="comparisionUrl"
                    className="leading-7 text-black font-medium"
                  >
                    Comparision Url (optional)
                  </label>
                  <TextField
                    id="comparisionUrl outlined-basic"
                    name="comparisionUrl"
                    placeholder="John Wick"
                    onChange={formik.handleChange}
                    value={formik.values.comparisionUrl}
                    onBlur={formik.handleBlur}
                    size="small"
                    className=" bg-black/5"
                  />
                </div>
                <div className="grid grid-cols-2 items-center">
                  <div className="flex items-center justify-start gap-4  mb-4">
                    <label
                      className="leading-7 text-black font-medium"
                    >
                      Product available
                    </label>
                    <span>
                      <Switch
                        checked={productAvailableCheck}
                        onChange={() => {
                          featureHandler("productAvailable");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                  </div>
                  <div className="flex items-center justify-start gap-4  mb-4">
                    <label
                      className="leading-7 text-black font-medium"
                    >
                      Display product
                    </label>
                    <span>
                      <Switch
                        checked={productDisplayCheck}
                        onChange={() => {
                          featureHandler("productDisplay");
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </span>
                  </div>
                </div>

                <div className="relative mb-4 flex flex-col">
                  <InputLabel id="shape-select-label">Select Shapes (optional)</InputLabel>
                  <FormControl size="small">
                    <Select
                      labelId="shape-select-label"
                      id="shapes"
                      name="shapes"
                      multiple
                      value={formik.values.shapes || []}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      renderValue={(selected) => selected.join(', ')}
                      className=" bg-black/5"
                    >
                      <MenuItem value="SQUARE">
                        <Checkbox checked={formik.values.shapes?.includes('SQUARE')} />
                        <ListItemText primary="SQUARE" />
                      </MenuItem>
                      <MenuItem value="ROUND">
                        <Checkbox checked={formik.values.shapes?.includes('ROUND')} />
                        <ListItemText primary="ROUND" />
                      </MenuItem>
                      <MenuItem value="RECTANGLE">
                        <Checkbox checked={formik.values.shapes?.includes('RECTANGLE')} />
                        <ListItemText primary="RECTANGLE" />
                      </MenuItem>
                      <MenuItem value="DIAMOND">
                        <Checkbox checked={formik.values.shapes?.includes('DIAMOND')} />
                        <ListItemText primary="DIAMOND" />
                      </MenuItem>
                      <MenuItem value="HEART">
                        <Checkbox checked={formik.values.shapes?.includes('HEART')} />
                        <ListItemText primary="HEART" />
                      </MenuItem>
                      <MenuItem value="OVAL">
                        <Checkbox checked={formik.values.shapes?.includes('OVAL')} />
                        <ListItemText primary="OVAL" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="w-full bg-white drop-shadow-md rounded-lg">
              <div className="header flex justify-between rounded-t-lg bg-black/10 p-4">
                <h3 className="text-xl">Main Image</h3>
              </div>
              <div className="flex flex-col items-center justify-center w-full gap-4 p-4">

                <label for="dropzone-file" className="flex flex-col text-center items-center justify-center w-11/12 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-3 pb-3">
                    <svg className="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Main Image</p>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                  </div>
                  <input id="dropzone-file" type="file" accept=".jpg, .jpeg, .png, .svg" className="hidden" onChange={handleMainImageChange} />
                </label>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-start justify-center">

                  {selectedMainImage && (
                    <div className="selectedImage bg-gray-200 h-48 rounded-lg flex flex-col items-center justify-between">
                      <h4 className="text-center">Selected Image</h4>
                      <div className="relative shadow-md">
                        <span
                          className="absolute right-0 top-0 cursor-pointer"
                          onClick={() => {
                            setSelectedMainImage(null);
                          }}
                        >
                          <IoCloseCircleSharp color="red" size={28} />{" "}
                        </span>
                        <img
                          className="shadow-md object-cover rounded-b-lg h-40"

                          src={selectedMainImage}
                          alt=""
                        />
                      </div>
                    </div>
                  )}

                  {/* {uploadedMainImage && (
                    <div className="uploadedImage bg-gray-200 h-48 rounded-lg flex flex-col items-center justify-between">
                      <h4 className="text-center">Uploaded images</h4>
                      <img
                        
                        src={uploadedMainImage}
                        alt=""
                        className="shadow-md object-cover rounded-b-lg h-40"
                      />
                    </div>
                  )} */}

                </div>
                {/* {
                  selectedMainImage
                  &&
                  <button
                    disabled={mainImageLoading}
                    onClick={uploadMainImageHandler}
                    className={`my-2 rounded-lg border border-none border-gray-300 bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out ${mainImageLoading
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                      }`}
                  >
                    {mainImageLoading ? "Uploading" : "Upload"}
                  </button>
                } */}
              </div>
            </div>
          </div>

          <div className="w-full md:w-6/12 flex flex-col gap-4">
            <div className="w-full bg-white drop-shadow-md rounded-lg">
              <div className="header flex justify-between rounded-t-lg bg-black/10 p-4">
                <h3 className="text-xl">Gallery</h3>
              </div>
              <div className="flex flex-col items-center justify-center w-full gap-4 p-4">

                <label for="dropzone-file-multiple" className="flex flex-col text-center items-center justify-center w-11/12 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div className="flex flex-col items-center justify-center pt-3 pb-3">
                    <svg className="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Gallery Images</p>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                  </div>
                  <input id="dropzone-file-multiple" type="file" accept=".jpg, .jpeg, .png, .svg" multiple className="hidden" onChange={handleGallaryImageChange} />
                </label>
                <div className="w-full px-6">

                  {selectedGallaryImages?.length > 0 && (
                    <div className="selectedImage mb-4 w-full flex flex-col items-center justify-center gap-2 border-2 border-black/10 bg-black/5 rounded-lg p-4">
                      <h4 className="text-black ">Selected Images</h4>
                      <div className="flex items-center justify-center flex-wrap gap-2 w-full">
                        {selectedGallaryImages.map((img, mainIndex) => (
                          <div className="relative " key={mainIndex}>
                            <span
                              className="absolute right-0 top-0 cursor-pointer"
                              onClick={() => {
                                filterGallaryImages(mainIndex);
                              }}
                            >
                              <IoCloseCircleSharp color="red" size={20} />{" "}
                            </span>
                            <img
                              className="object-cover rounded-lg h-32"
                              src={img}
                              alt="gallery image"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* {uploadedGallaryImagesLink?.length > 0 && (
                    <div className="uploadedImage w-full flex flex-col items-start justify-center gap-2 border-2 border-black/10 bg-black/5 rounded-lg p-4">
                      <h4 className="text-black">Uploaded Images</h4>
                      <div
                        className="flex items-center justify-start flex-wrap gap-2 w-full"

                      >
                        {uploadedGallaryImagesLink?.map((img, index) => (
                          <img
                            key={index}
                            className="object-cover rounded-lg h-32"
                            src={img}
                            alt="uploaded gallery image"
                          />
                        ))}
                      </div>
                    </div>
                  )} */}
                </div>
                {/* {selectedGallaryImages?.length > 0
                  &&
                  <button
                    disabled={gallaryImageLoading}
                    onClick={updloadGallaryImagesHandler}
                    className={`my-2 rounded-lg border border-none border-gray-300 bg-brand-main px-4 py-2 text-sm text-white outline-none transition duration-300 ease-in-out ${gallaryImageLoading
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                      }`}
                  >
                    {gallaryImageLoading ? "Uploading" : "Upload"}
                  </button>
                } */}


              </div>
            </div>
            <div className="w-full bg-white drop-shadow-md rounded-lg">
              <div className="header flex justify-between rounded-t-lg bg-black/10 p-4">
                <h3 className="text-xl">Try On Image</h3>
              </div>
              <div className="flex flex-col items-center justify-center w-full gap-4 p-4">
                <Select
                  size="small"
                  labelId="category-select-label"
                  value={selectedTryOnType?.value}
                  onChange={handleTryOnTypeChange}
                  className="rounded-md border-gray-50 outline-none place-self-start ml-4 sm:ml-6"
                >
                  {tryOnTypes.map((type, index) => (
                    <MenuItem key={index} value={type?.value}>
                      {type?.text}
                    </MenuItem>
                  ))}
                </Select>

                <div onClick={() => setOpenTryOnModal(true)} className="p-4 rounded-lg flex flex-col items-center justify-center border border-gray-200 hover:bg-gray-200 cursor-pointer">
                  <svg className="w-20 h-20 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                  </svg>
                  <span>Click to upload Try-On Image</span>
                </div>

                <Modal open={openTryOnModal} onClose={() => setOpenTryOnModal(false)} className="flex justify-center items-center">
                  <div className="bg-white p-6 rounded-lg w-96">
                    <h2 className="text-xl mb-4">Upload Try On Image</h2>

                    {/* Color Input */}
                    {/* <TextField
                      label="Color"
                      value={color}
                      type="color"
                      onChange={(e) => setColor(e.target.value)}
                      fullWidth
                      className="mb-4"
                    /> */}
                    <SliderPicker
                      color={color}
                      onChangeComplete={(e) => {
                        console.log(e);
                        setColor(e.hex)
                      }
                      }
                    />

                    <label for="try-on-image-upload" className="mt-2 flex flex-col text-center items-center justify-center w-full my-2 h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                      <div className="flex flex-col items-center justify-center pt-3 pb-3">
                        <svg className="w-8 h-8 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                        </svg>
                        <p className="text-sm text-gray-500 dark:text-gray-400">Try On Image</p>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                      </div>
                      <input id="try-on-image-upload" type="file" accept=".jpg, .jpeg, .png, .svg" className="hidden" onChange={handleTryOnImageChange} />
                    </label>

                    {selectedTryOnImage && (
                      <div className="mb-4 text-center">
                        <img className="object-contain h-40 w-full" src={selectedTryOnImage} alt="Selected Try On" />
                      </div>
                    )}
                    <Button onClick={handleDone} variant="contained" color="primary" fullWidth>
                      Done
                    </Button>
                  </div>
                </Modal>
                <div className="flex items-center justify-center gap-2">
                  {
                    tryOnImages?.map((image, index) => (
                      <div className="selectedImage bg-gray-200 h-44 rounded-lg flex flex-col items-center justify-between">
                        <h4 className="text-center flex gap-4 items-center justify-between">{tryOnColors[index]}
                          <span
                            className="right-0 top-0 cursor-pointer"
                            onClick={() => {
                              const updatedTryOnImages = tryOnImages?.filter((item, i) => i !== index)
                              const updatedTryOnColors = tryOnColors?.filter((item, i) => i !== index)
                              setTryOnColors(updatedTryOnColors)
                              setTryOnImages(updatedTryOnImages)
                            }}
                          >
                            <IoCloseCircleSharp color="red" size={28} />{" "}
                          </span>
                        </h4>
                        <div className="relative shadow-md p-1">
                          <img
                            className="object-contain rounded-b-lg h-36"
                            // width={200}
                            src={image}
                            alt=''
                          />
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </Dialog>
    </React.Fragment>
  );
}
