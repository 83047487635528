import { useState, useEffect, useRef } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import Pagination from "@mui/material/Pagination";
import { saveAs } from "file-saver";
import Skeleton from "@mui/material/Skeleton";

import {
  SET_INITIAL_STATE_AR,
  SET_HISTORY_DATA,
  SET_UPDATE_STATE_AR,
  SET_HISTORY_CURRENT_PAGE,
  SET_HISTORY_TOTAL_PAGE,
  SET_HISTORY_DATA_FOR_SIDEBAR,
} from "../../../redux/reducerSlice/worldTrackSlice";

import { SET_AUTH } from "../../../redux/reducerSlice/authSlice";

import InputField from "components/fields/InputField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import {
  MdDelete,
  MdOpenInFull,
  MdOutlineClear,
  MdOutlineQrCodeScanner,
} from "react-icons/md";
import { setViewCreated } from "../../../redux/reducerSlice/valueSlice";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from "components/dropdown";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaPlus, FaRegEye } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
// import { TextField } from "@mui/material";
import { FaPlusCircle } from "react-icons/fa";

const style = {
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 400,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  transform: "translate(-50%, -50%)",
  borderRadius: "0.5rem",
  outline: "none",
};

const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 180,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

const WorldTracking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userid = useSelector((state) => state.auth.auth.user._id);
  const allowedExperiencesCount = useSelector(
    (state) => state.auth.auth.user?.allowedExperiencesCount
  );
  const userToken = useSelector((state) => state.auth.auth.token);

  const user = useSelector((state) => state.auth.auth.user);
  const auth = useSelector((state) => state.auth.auth);

  const historyData = useSelector((state) => state.WorldTrack.historyData);
  console.log("historyData: ", historyData);
  const currentPage = useSelector(
    (state) => state.WorldTrack.currentPageHistory
  );
  const totalPages = useSelector((state) => state.WorldTrack.totalPagesHistory);
  const [itemsPerPage, setSelectedValue] = useState(5);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const [openDelete, setOpenDelete] = useState(null);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");

  const handleDeleteClose = () => setOpenDelete(null);

  const forSideBar = async () => {
    try {
      const response = await instance.get(
        `api/WTracking/WorldTrackingList_history/${userid}?page=${currentPage}&limit=5000&searchText=${searchParam}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_HISTORY_DATA_FOR_SIDEBAR(response?.data?.data));
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
      setLoading(false);
    }
  };

  const getHistoryData = async () => {
    if (!historyData) {
      setLoading(true);
    }

    console.log("itemsPerPage: ", itemsPerPage);

    try {
      const response = await instance.get(
        `api/WTracking/WorldTrackingList_history/${userid}?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchParam}`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_HISTORY_DATA(response?.data?.data));
        dispatch(SET_HISTORY_TOTAL_PAGE(response?.data?.totalPages));

        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistoryData();
  }, [currentPage, searchParam, itemsPerPage]);

  const getUserDetails = async () => {
    try {
      const response = await instance.get(`/api/auth/getUser/${userid}`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });

      const userData = {
        ...user,
        allowedExperiencesCount: response.data.data.allowedExperiencesCount,
      };

      console.log(userData, "userData");
      dispatch(SET_AUTH({ ...auth, user: userData }));
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  useEffect(() => {
    getUserDetails();
    forSideBar();
  }, []);

  // const handleSearch = (e) => {
  //   const inputValue = e.target.value;
  //   setSearch(inputValue);
  //   if (!inputValue || inputValue.trim() === "") {
  //     setSearchParam("");
  //   } else {
  //     setSearchParam(inputValue.trim());
  //   }
  // };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleChange = (event, value) => {
    dispatch(SET_HISTORY_CURRENT_PAGE(value));
  };

  const handleChangeRows = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setSelectedValue(newItemsPerPage);

    const newPage = Math.min(
      currentPage,
      Math.ceil(historyData.length / newItemsPerPage)
    );
    dispatch(SET_HISTORY_CURRENT_PAGE(newPage));
  };

  return (
    <div >
      <div className="relative mb-10 z-10 mt-1 flex w-full flex-wrap items-center justify-between rounded-lg bg-white p-4 shadow-sm">
        <input
          className="h-10 w-full rounded-lg border border-gray-300 p-2 text-sm focus:outline-none focus:ring-2 focus:ring-brand-main md:w-[30%]"
          placeholder="Search the Experience Name"
          value={search}
          onChange={handleSearch}
        />

        {/* Middle section with Create New Experience Button */}
        <div className="flex flex-col items-center justify-center space-y-2">
          {/* Create Experience Button */}
          <div
            className={`hover:bg-brand-dark group relative flex h-[40px] w-[200px] cursor-pointer items-center justify-center rounded-lg bg-brand-main text-white shadow-md transition duration-300`}
            onClick={() => {
              if (
                historyData?.length <
                (allowedExperiencesCount ? allowedExperiencesCount : 1)
              ) {
                dispatch(SET_INITIAL_STATE_AR());
                handleOpen();
              } else {
                toast.error(
                  `The user has exceeded the limit for creating experiences`
                );
              }
            }}
          >
            <FaPlus className="mr-2" size={16} />
            <span className="text-sm font-medium">Create Experience</span>
          </div>

         
       
        </div>

        {/* Experience per page on the Right */}
        <div className="flex items-center gap-3">
          <p className="text-sm text-gray-600">Experience per page</p>
          <select
            className="h-10 w-20 rounded-lg border border-gray-300 bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-brand-main"
            value={itemsPerPage}
            onChange={handleChangeRows}
          >
            {[...Array(12).keys()].map((_, index) => (
              <>
                {index > 0 && (
                  <option key={index} value={index * 5}>
                    {index * 5}
                  </option>
                )}
              </>
            ))}
          </select>
        </div>
      </div>

     

      {historyData?.length > 0 &&
        historyData.map((element, index) => {
          return (
            <div
              key={index}
              className="mb-2 flex w-full items-center justify-between rounded-md border bg-white px-4 py-4 shadow-sm"
            >
             
              <div className="flex w-full items-center justify-between gap-4 text-center">
               
                <p className="flex-1 text-center text-sm font-semibold">
                  {element?.name}
                </p>

                <p className="flex-1 text-center text-xs text-gray-500">
                  {new Date(element?.createdAt).toLocaleDateString()}
                </p>

            
                <span
                  className={`inline-block flex-1 rounded px-2 py-1 text-center text-xs font-bold ${
                    element.status === "published"
                      ? "bg-green-200 text-green-800"
                      : element.status === "draft"
                      ? "bg-yellow-200 text-yellow-800"
                      : "bg-red-200 text-red-800"
                  }`}
                >
                  {element?.status}
                </span>


                <div className="flex flex-1 items-center justify-center gap-4">
                 
                  <button
                    className="rounded bg-[#3d2fa9] px-3 py-1 text-xs text-white hover:bg-[#3d2fa9]"
                    onClick={() => {
                      dispatch(SET_UPDATE_STATE_AR(element));
                      dispatch(setViewCreated(""));
                      navigate(`/admin/world_experience/${element.name}`);
                    }}
                  >
                    Edit
                  </button>

                  <MdDelete
                    size={18}
                    className="cursor-pointer text-red-400"
                    onClick={() => setOpenDelete(element._id)}
                  />
                </div>
              </div>
            </div>
          );
        })}

      <div className="mt-2 px-10 md:px-20">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={handleChange}
        />
      </div>



      <CreateExperience
        open={open}
        handleClose={handleClose}
        getHistoryData={getHistoryData}
      />
      <Delete
        _id={openDelete}
        handleClose={handleDeleteClose}
        getHistoryData={getHistoryData}
      />
    </div>
  );
};

const CreateExperience = ({ open, handleClose, getHistoryData }) => {
  const [loading, setLoading] = useState(false);
  const [experienceAvailable, setExperienceAvailable] = useState(true);
  const [experienceName, setExperienceName] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.auth.auth.token);
  const arData = useSelector((state) => state.WorldTrack.data);

  const userid = useSelector((state) => state?.auth?.auth?.user?._id);
  const userName = useSelector((state) => state?.auth?.auth?.user?.username);
  const resellerId = useSelector(
    (state) => state?.auth?.auth?.user?.resellerId
  );

  const dispatch = useDispatch();

  const createARExperience = async () => {
    setLoading(true);
    try {
      const response = await instance.post(
        `/api/WTracking/create_WorldTracking`,
        {
          ...arData,
          userId: userid,
          userName: userName,
          name: name,
          worldTrackingName: experienceName,
          ...(resellerId && { resellerId: resellerId }),
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        const _id = response.data.data.insertedId;

        dispatch(
          SET_UPDATE_STATE_AR({
            ...arData,
            name: name,
            userId: userid,
            worldTrackingName: experienceName,
            _id,
            ...(resellerId && { resellerId: resellerId }),
          })
        );

        dispatch(setViewCreated(""));

        getHistoryData();
        navigate(`/admin/world_experience/${name}`);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const checkExperienceExist = async (nameparam) => {
    try {
      const response = await instance.post(
        "/api/WTracking/check_WorldTracking_name",
        {
          name: nameparam,
          userName: userName,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      setExperienceAvailable(response.data.data);
    } catch (error) {
      return false;
    }
  };

  const validationSchema = Yup.object({
    experienceName: Yup.string()
      .min(4, "Experience must be at least 4 characters")
      .required("Experience Name is required")
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        "Valid characters:letters,numbers,and underscores. "
      )
      .test("Ok", "Experience already exists", async () => {
        return experienceAvailable;
      }),
  });
  const formik = useFormik({
    initialValues: {
      experienceName: experienceName,
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      console.log(values, "values");
      await createARExperience();
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="mt-2  flex  w-full flex-col justify-center p-4 outline-none  ">
          <div className="flex w-full items-center justify-center gap-2">
            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Experience Name*"
                placeholder="Enter Experience Name"
                id="experienceName"
                type="text"
                onChange={(event) => {
                  formik.setFieldValue(
                    "experienceName",
                    event.target.value.toLowerCase()
                  );

                  setExperienceName(event.target.value.toLowerCase());
                  let inputValue = event.target.value.toLowerCase();
                  let result = inputValue.replace(/\s+/g, "-");

                  setName(result);
                  checkExperienceExist(result);
                }}
                value={formik.values.experienceName}
                error={
                  formik.touched.experienceName && formik.errors.experienceName
                }
              />
            </div>
          </div>
          <div className="mt-2 text-center text-sm">
            <button
              onClick={formik.handleSubmit}
              type="submit"
              className={`rounded-lg border border-gray-200 ${
                !experienceAvailable ? "bg-gray-600" : "bg-[#3d2fa9]"
              }   p-2 text-sm font-normal text-white`}
              disabled={!experienceAvailable}
            >
              Create Experience
            </button>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <h3 className=" text-lg font-bold ">Creating AR experience</h3>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </Box>
    </Modal>
  );
};

const Delete = ({ _id, handleClose, getHistoryData }) => {
  console.log(_id);
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);

  const handleDelete = async (_id) => {
    setLoading(true);
    console.log(_id);
    try {
      const response = await instance.delete(
        `api/WTracking/WorldTracking_delete/${_id}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        handleClose();
        setLoading(false);
        getHistoryData();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={_id}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleDelete} className="rounded-lg">
        <div className="flex items-center justify-between rounded-t-lg  bg-gray-200 p-2">
          <p className="text-lg font-bold ">Immarsify</p>
          <span onClick={handleClose} className="cursor-pointer">
            <MdOutlineClear size={20} />
          </span>
        </div>
        <p className="text-md p-4">Are you sure, you want to delete ?</p>

        <div className="absolute bottom-2 right-2 mt-2 flex items-center justify-center gap-2 p-2 ">
          <button
            className="rounded-lg border bg-[#3d2fa9] px-4 py-2 text-white "
            onClick={() => handleDelete(_id)}
          >
            yes
          </button>
          <button
            className="rounded-lg border border-gray-500 px-4 py-2 text-gray-900 "
            onClick={handleClose}
          >
            No
          </button>
        </div>
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <h1 className="font-bold">Delete AR Experience</h1>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </Box>
    </Modal>
  );
};

export default WorldTracking;
