import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import { LOGOUT } from "../../redux/reducerSlice/rootSlice";
import {
  SET_TEMPLATE_DATA,
  SET_HISTORY_CURRENT_PAGE,
  SET_HISTORY_TOTAL_PAGE,
} from "../../redux/reducerSlice/arSlice";
import { SET_SIGNUP_EMAIL } from "../../redux/reducerSlice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
const Navbar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onOpenSidenav, brandText } = props;
  const user = useSelector((state) => state.auth.auth.user);

  return (
    <nav className="sticky top-1 z-40 mb-0 mt-2.5 flex flex-row flex-wrap items-center justify-between rounded-xl border bg-white p-2.5 px-2.5  shadow-lg backdrop-blur-xl  dark:bg-[#0b14374d]">
      <div className="ml-[6px]  hidden xl:block">
        <div className="h-6 w-[224px] ">
          <a className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white">
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link className="text-md font-bold  capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white">
            {brandText}
          </Link>
        </div>
        {/* <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
           
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p> */}
      </div>

      <div className="relative mt-[3px] flex  flex-grow items-center justify-end  gap-2 rounded-full   ">
        <span
          className="flex  cursor-pointer text-xl text-gray-600 xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5 cursor-pointer" />
        </span>

        <Dropdown
          button={
            user?.image ? (
              <img
                src={user?.image}
                className="h-10 w-10 cursor-pointer rounded-full object-cover"
                alt="user avatar"
              />
            ) : (
              <Avatar
                size={40}
                round={true}
                name={user?.username}
                className="cursor-pointer"
              />
            )
          }
          children={
            <div className="z-50 flex  w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none ">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white ">
                    👋 Hello, {user?.username}
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="flex flex-col p-4">
                <p
                  onClick={() => {
                    navigate("/admin/profile");
                  }}
                  className="cursor-pointer text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile
                </p>

                <p
                  href=" "
                  className="mt-3 cursor-pointer text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={() => {
                    navigate("/auth/sign-in");
                    dispatch(LOGOUT());
                    dispatch(SET_SIGNUP_EMAIL(""));
                    dispatch(SET_TEMPLATE_DATA());
                    dispatch(SET_HISTORY_TOTAL_PAGE(0));
                    dispatch(SET_HISTORY_CURRENT_PAGE(1));
                  }}
                >
                  Log Out
                </p>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
