// src/components/ProductCard.js
import React, { useEffect, useRef, useState } from "react";
import { CiMenuKebab } from "react-icons/ci";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import EditProductModal from "./Modal/EditProductModal";
import DeleteModal from "./Modal/DeleteModal";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import "./ProductCard.css";
import { RiMenu3Fill } from "react-icons/ri";
import { TRY_ON_URL } from "../../../../constant";

const ProductCard = ({ product, getImmersifyProducts }) => {
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [showOpenDeleteModal, setShowOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userToken = useSelector((state) => state.auth.auth.token);
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const open = Boolean(anchorEl);
  const handleClickAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const HandleOpenEditProductModal = () => {
    setShowEditProductModal(true);
    setAnchorEl(null);
  };

  const opneDeleteModalHandler = () => {
    setShowOpenDeleteModal(true);
    setAnchorEl(null);
  };
  const handleCloseAnchor = () => {
    setShowOpenDeleteModal(false);

    setAnchorEl(null);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const navigateToTryOn = () => {
    window.open(`${TRY_ON_URL}/${product?.userId}/${product?._id}`, '_blank')
  }

  const deleteProductHandler = async () => {
    try {
      const response = await instance.delete(
        `api/products/delete_product/${product?._id}`,

        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "this is repsonse");
      if (response.status === 200) {
        toast.success(response?.data?.message);
        getImmersifyProducts();
        setTimeout(() => {
          setShowOpenDeleteModal(false);
        }, 500);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log("Dashboard Error: ", error);
    }
  };
  return (

    <div className="relative bg-gray-50 border border-gray-200 rounded-lg drop-shadow-md dark:bg-gray-800 dark:border-gray-700">

      <div className={`absolute z-10 top-2 left-2 flex justify-end rounded-full font-medium text-white px-3 opacity-85 py-1 ${product?.isProductAvailable ? 'bg-green-600' : 'bg-red-600'}`}>
        {product?.isProductAvailable ? "Available" : "Out of Stock"}
      </div>
      <div className="absolute z-10 top-0 right-0 flex justify-end px-2 pt-2" ref={dropdownRef}>
        <button
          id="dropdownButton"
          onClick={handleClickAnchor}
          className="inline-block rounded-lg text-sm"
          type="button"
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          <RiMenu3Fill size={28} className="bg-gray-200/70 hover:bg-gray-200 text-black p-1 rounded-md" />
        </button>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseAnchor}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={HandleOpenEditProductModal}>Edit</MenuItem>
          <MenuItem onClick={opneDeleteModalHandler}>Delete</MenuItem>
        </Menu>
      </div>

      <img className="rounded-t-lg w-full min-h-40 max-h-40 object-cover"
        src={product?.mainImage || "https://via.placeholder.com/150"}
        alt={product?.name || "Default product image"}
      />
      {/* <div onClick={navigateToTryOn} className=" absolute top-[7.5rem] text-white font-medium px-2.5 py-1 rounded-xl right-2 z-10 bg-black/80 cursor-pointer hover:bg-black/95">
        Try
      </div> */}

      <div className="p-4 bg-gray-50">
        <div className="flex items-center justify-between gap-3">
          <h5 className="text-lg font-bold tracking-tight text-black/95 dark:text-white">
            {product?.name || "Product Name not availabe"}
          </h5>
          <p className=" text-black/90 font-bold"> ${product?.price || "0.00"} { product?.price !== product?.originalPrice && product?.discount !== 0 && <s className="text-opacity-50 font-normal">${product?.originalPrice}</s>}</p>
        </div>

        <p className="font-normal text-sm text-gray-700 dark:text-gray-400">
          {truncateText(product?.description, 100) || "Description not available"}
        </p>
      </div>
      {
        showEditProductModal
        &&
        <EditProductModal
          isOpen={showEditProductModal}
          setIsOpen={setShowEditProductModal}
          product={product}
          getImmersifyProducts={getImmersifyProducts}
        />
      }
      <DeleteModal
        isOpen={showOpenDeleteModal}
        setIsOpen={setShowOpenDeleteModal}
        content="Please confirm to delete this product!!!"
        handler={deleteProductHandler}
      />
    </div>

    // <div className="min-w-56 max-w-60 overflow-hidden rounded-md bg-white shadow-lg">
    //   <div className="page-inner">
    //     <div className="el-wrapper">
    //       <div className="box-up">
    //         <img
    //           className="img h-48 w-full object-cover"
    //           src={product?.mainImage || "https://via.placeholder.com/150"}
    //           alt={product?.name || "Default product image"}
    //         />
    //         <div className="img-info">
    //           <div className="info-inner">
    //             <span className="p-name">
    //               {product?.name || "Product Name not availabe"}
    //             </span>
    //             <span className="p-company">
    //               {truncateText(product?.description, 100) ||
    //                 "Description not available"}
    //             </span>
    //           </div>
    //           <div
    //             className={`a-size ${
    //               product?.isProductAvailable
    //                 ? "text-green-400"
    //                 : "text-red-400"
    //             }`}
    //           >
    //             {product?.isProductAvailable ? "Available" : "Out of Stock"}
    //           </div>
    //         </div>
    //       </div>

    //       <div className="box-down flex items-center justify-between px-6 pb-2">
    //         <span className="price text-lg font-bold text-gray-700">
    //           ${product?.price || "0.00"}
    //         </span>
    //         <div className="mt-2">
    //           <IconButton
    //             aria-label="more"
    //             id="long-button"
    //             aria-controls={open ? "long-menu" : undefined}
    //             aria-expanded={open ? "true" : undefined}
    //             aria-haspopup="true"
    //             onClick={handleClickAnchor}
    //           >
    //             <CiMenuKebab className="text-white" />
    //           </IconButton>
    //           <Menu
    //             id="long-menu"
    //             MenuListProps={{
    //               "aria-labelledby": "long-button",
    //             }}
    //             anchorEl={anchorEl}
    //             open={open}
    //             onClose={handleCloseAnchor}
    //           >
    //             <MenuItem onClick={HandleOpenEditProductModal}>Edit</MenuItem>
    //             <MenuItem onClick={opneDeleteModalHandler}>Delete</MenuItem>
    //           </Menu>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <EditProductModal
    //     isOpen={showEditProductModal}
    //     setIsOpen={setShowEditProductModal}
    //     product={product}
    //     getImmersifyProducts={getImmersifyProducts}
    //   />
    //   <DeleteModal
    //     isOpen={showOpenDeleteModal}
    //     setIsOpen={setShowOpenDeleteModal}
    //     content="Please confirm to delete this product!!!"
    //     handler={deleteProductHandler}
    //   />
    // </div>
  );
};

export default ProductCard;
