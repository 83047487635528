
import React, { useState } from 'react'

const HtmlEmbedScript = () => {

    const [divId, setDivId] = useState("Enter your ID");


    const copyToClipboard = () => {
        const textToCopy = `<script \n  data-target-div="${divId}"\n  src='https://tryon.immarsify.com/script/data.js'\n/>`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('Script copied to clipboard!');
        });
    };

    const handleInputChange = (e) => {
        setDivId(e.target.value);
    };

    return (
        <div className="container mx-auto px-4 py-6 ">
            <div className="mb-4 overflow-visible rounded-lg shadow-lg bg-white p-4">
                <div className="rounded-lg bg-white px-4 py-3">
                    <h1 className="text-xl font-bold text-[#979898]">HTML Embed Script</h1>
                </div>
                <div className='w-full grid grid-cols-1 md:grid-cols-2'>
                    <div className="bg-white px-4 py-6">
                        <div className="w-full">
                            <label htmlFor="divId" className=' font-medium text-gray-900'>Enter Div ID : </label>
                            <input
                                id='divId'
                                type="text"
                                placeholder="Enter Div ID"
                                value={divId}
                                onChange={handleInputChange}
                                className="w-full rounded-lg mt-2 border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    </div>
                    <div className=" rounded-lg flex flex-col items-stretch justify-start px-4 py-4 bg-white">
                        <textarea
                            rows="5"
                            readOnly
                            value={`<script \n  data-target-div="${divId}"\n  src='https://tryon.immarsify.com/script/data.js'\n/>`}
                            className="w-full rounded-lg border border-gray-300 bg-gray-200 px-4 py-2 focus:outline-none"
                        />
                        <button
                            onClick={copyToClipboard}
                            className="bg-blue-500 text-white rounded-lg px-4 py-2 mt-2 place-self-end"
                        >
                            Copy to Clipboard
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default HtmlEmbedScript;