import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { instance } from "common/Instance";
import InputField from "components/fields/InputField";
import toast from "react-hot-toast";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { SET_AUTH, SET_SIGNUP_EMAIL } from "../../redux/reducerSlice/authSlice";
import { SET_HISTORY_DATA } from "../../redux/reducerSlice/arSlice";
import Cookies from "js-cookie";
import { FaEye, FaEyeSlash } from "react-icons/fa";


export default function Signin() {
  const [loading, setLoading] = useState(false);
  const [remember, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isTryon = useSelector((state) => state?.auth?.auth?.user?.isTryon);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectTemplateURL = useSelector(
    (state) => state?.auth?.redirectTemplateURL
  );

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      ssoLogin(token);
    }
  }, [location.search]);

  const ssoLogin = async (token) => {
    setLoading(true);
    try {
      const response = await instance.get("api/auth/ssoAdminVerify", {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        toast.success("Successfully Login!");
        dispatch(SET_AUTH(response.data.data));

        if (redirectTemplateURL) {
          navigate(redirectTemplateURL);
        } else {
          navigate("/admin/AR_history");
        }

        dispatch(SET_HISTORY_DATA());

        if (remember) {
          Cookies.set("token", token, { expires: 7 }); // Store token in cookies for 7 days
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  const handleResendOTP = async (email) => {
    try {
      const response = await instance.post("api/auth/resend-otp", {
        email,
      });
      if (response.status === 200) {
        toast.success("OTP sent successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const validationSchema = Yup.object({
    identifier: Yup.string()
      .required("Email or username is required")
      .test(
        "email-or-username",
        "Must be a valid email or username",
        function (value) {
          if (!value) return false;
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value) || value.length >= 4;
        }
      ),
    password: Yup.string()
      .min(4, "Password must be at least 4 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      identifier: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const isEmail =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            values.identifier
          );
        const payload = {
          [isEmail ? "email" : "username"]: values.identifier,
          password: values.password,
          rememberMe: remember,
        };

        const response = await instance.post("api/auth/login", payload);

        if (response.status === 200) {
          if (response?.data?.data?.user?.emailVerified) {
            setLoading(false);
            toast.success("Successfully Login!");
            dispatch(SET_AUTH(response.data.data));


            console.log(response.data.data,'data1')

            if (redirectTemplateURL) {
              navigate(redirectTemplateURL);
            } else {
              if (response?.data?.data?.user?.isTryon) {
                navigate("/admin/products");
               
              } else {
                navigate("/admin/AR_history");
                
              }
            }
            dispatch(SET_HISTORY_DATA());

            if (remember) {
              Cookies.set("token", response.data.data.token, { expires: 7 });
            }
          } else {
            setLoading(false);
            dispatch(SET_SIGNUP_EMAIL(values.identifier));
            toast.success("Please Verify Through OTP!");
            navigate("/auth/otp");
            handleResendOTP(values.identifier);
          }
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error.message);
        setLoading(false);
      }
    },
  });

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[2vh] w-full max-w-full justify-center flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-4 ml-1 mt-4 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <div className="mb-4 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email or Username*"
          placeholder="Email or Username"
          id="identifier"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.identifier}
          error={formik.touched.identifier && formik.errors.identifier}
        />
        {/* Password */}
        <div className="relative">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 4 characters"
            id="password"
            type={showPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-[54px] -translate-y-1/2 transform"
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        <div className="mb-4 flex items-center justify-between px-2">
          <div>
            <input
              type="checkbox"
              id="rememberMe"
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label
              htmlFor="rememberMe"
              className="ml-2 text-sm font-medium text-navy-700 dark:text-white"
            >
              Remember Me
            </label>
          </div>
          <Link
            className="text-sm font-medium text-brand-main hover:text-brand-600 dark:text-white"
            to="/auth/password"
          >
            Forgot Password?
          </Link>
        </div>
        <button
          className={`linear mt-2 flex w-full justify-center rounded-xl bg-brand-500 px-10  ${
            loading ? "py-[6px]" : "py-[12px]"
          } text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <>
              <CircularProgress sx={{ color: "white" }} size={30} />
            </>
          ) : (
            "Sign in"
          )}
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <Link
            to="/auth/sign-up"
            className="ml-1 text-sm font-medium text-brand-main hover:text-brand-600 dark:text-white"
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
}
